
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import Table from '@/components/organisms/dms/common/Table.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'OuterContentsInput',
  components: {
    AppButton,
    Table,
    draggable,
  },
  props: {
    fileList: {
      type: Array,
    },
  },
  setup (props, { emit }) {
    const store = useStore()
    const items = computed({
      get: () => props.fileList,
      set: (items) =>
        emit('update:fileList', items),
    })
    const header = ref([
      { key: 'no', name: 'No', width: '15%' },
      { key: 'fileName', name: 'ファイル名', width: '70%' },
      { key: '', name: '', width: '' },
    ])
    const setReacquireFlag = async (flag: boolean) => {
      await store.dispatch('setReacquireFlag', flag)
    }
    return {
      items,
      props,
      header,

      async deleteFile (num: number) {
        const delItem = items.value?.splice(num, 1)
        await setReacquireFlag(true)
      },
    }
  },
})
