
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import OuterContentsFile from '@/components/organisms/dms/OuterContentsInput/OuterContentsFile.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import Message from '@/components/organisms/dms/common/Message.vue'
import InputImageFile from '@/components/molecules/InputImageFile.vue'
import { s3upload, uploadFileData } from '@/data/dms/s3upload'
import { Contents } from '@/data/dms/dmsData'

export default defineComponent({
  name: 'OuterContentsInput',
  components: {
    OuterContentsFile,
    DssIcon,
    AppButton,
    Message,
    InputImageFile,
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const uploadFileList = ref<uploadFileData[]>(store.getters.s3UploadFileList) || ref<uploadFileData[]>([])
    const encFileList = ref<string[]>([])
    const reuseFlag = store.getters.reuseFlag ? store.getters.reuseFlag : false
    const reacquireFlag = computed<boolean>(() => store.getters.reacquireFlag)
    const setReacquireFlag = async (flag: boolean) => {
      await store.dispatch('setReacquireFlag', flag)
    }
    const isDisplay = ref(true)

    const messages = ref<{ id: string, type: string, text: string}[]>([])
    const removeMessage = (id: string) => {
      const ids = messages.value.map(a => a.id)
      const index = ids.indexOf(id)
      messages.value.splice(index, 1)
    }

    const setLoading = async (flag: boolean) => {
      await store.dispatch('setLoading', flag)
    }

    // 画面再読み込み、ブラウザバックなどの操作制御
    const onBeforeunloadHandler = async (e: any) => {
      e.returnValue = '行った変更が保存されない可能性があります。'
      await store.dispatch('setS3UploadFileList', [])
    }
    onBeforeRouteLeave(async (to, from, next) => {
      if (uploadFileList.value || to.name === 'OuterOutputSelectPage' || to.name === 'OuterTopPage' || to.name === 'OnlineLoginPage') {
        next()
      } else {
        const answer = window.confirm('行った変更が保存されない可能性があります。')
        if (answer) {
          next('/dms/outer/outputSelect')
        } else {
          next(false)
        }
      }
    })
    onMounted(async () => {
      window.addEventListener('beforeunload', onBeforeunloadHandler, false)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', onBeforeunloadHandler, false)
    })

    const isInvalidContentsUriNum = () => {
      const contentsData = computed<Contents>(() => store.getters.contentsData)
      const contentsUriList = contentsData.value.metadataOuters?.find((metadata: {id: string}) => metadata.id === '701')?.value || []
      return uploadFileList?.value.length < contentsUriList?.length
    }

    return {
      reuseFlag,
      router,
      uploadFileList,
      encFileList,
      messages,
      removeMessage,
      isDisplay,

      async fileUpload (file: FileList) {
        if (!file[0]) return
        await setLoading(true)
        if (file[0].size > 5 * 1024 * 1024 * 1024) {
          messages.value.push({
            id: String(messages.value.length + 1),
            type: 'error',
            text: '5GB 以上のファイルはアップロードできません。国立国会図書館へお問い合わせ下さい。',
          })
          await setLoading(false)
          return
        }
        // S3用のファイル詰め
        uploadFileList.value.push({ fileName: file[0].name, s3UploadFilePath: '', fileData: file[0] })
        await setReacquireFlag(true)
        await setLoading(false)
        isDisplay.value = true
        messages.value.push({
          id: String(messages.value.length + 1),
          type: 'success',
          text: `${file[0].name} をアップロードしました。`,
        })
        // setTimeout(() => removeMessage(String(messages.value.length + 1)), 3000) // メッセージ自動で消える設定
      },
      async next () {
        await setLoading(true)
        if (reacquireFlag.value) await s3upload(uploadFileList.value!)
        await window.fetch('')
        if (reuseFlag && isInvalidContentsUriNum()) {
          messages.value.push({
            id: String(messages.value.length + 1),
            type: 'error',
            text: 'アップロードされたファイルの数が、入力されたURLの数を下回っています。',
          })
          await setLoading(false)
          return
        }
        reuseFlag
          ? await router.push('/dms/outer/inputConfirm')
          : await router.push('/dms/outer/metadataInput')
      },
      async prev () {
        await window.fetch('') // DOMの更新のため空のFetchを挟む
        reuseFlag
          ? await router.push('/dms/outer/metadataInput')
          : await router.push('/dms/outer/outputSelect')
      },
    }
  },
})
